import { Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { change } from "../state/Nav";
import { TextBody, TextBox, TextHeader } from "../Styles";
import { hideKrpano } from "../Util";
import { RootState } from "../store";
import { ui } from "../i18n";

export default function About() {
    const lang = useSelector((state: RootState) => state.language.value);
    const dispatch = useDispatch();
    dispatch(change("about"));
    hideKrpano();

    return (
        <Box sx={{ paddingBottom: "60px" }}>
            <TextBox>
                <TextHeader>
                    {ui[lang].About.artspace}
                </TextHeader>
                <TextBody>
                    {ui[lang].About.artspacebody}
                </TextBody>
                <TextHeader marginTop="20px">
                    {ui[lang].About.venue}
                </TextHeader>
                <TextBody>
                    {ui[lang].About.venuebody1}
                </TextBody>
                <TextBody marginTop="10px">
                    {ui[lang].About.venuebody2}
                </TextBody>
            </TextBox>
        </Box>
    );
}