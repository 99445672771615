import { Box, Button, IconButton, Menu, MenuItem, MenuList, Select, SelectChangeEvent, useMediaQuery } from "@mui/material";
import MainNavigationItem from "./MainNavigationItem";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useState } from "react";
import MainNavigationItemMobile from "./MainNavigationItemMobile";
import { useDispatch, useSelector } from "react-redux";
import { changeLang } from "../state/Language";
import { RootState } from "../store";
import { ui } from "../i18n";

export default function MainNavigationMobile() {
    const dispatch = useDispatch();
    const lang = useSelector((state: RootState) => state.language.value);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
   
    const handleChange = (event: SelectChangeEvent) => {
        dispatch(changeLang(event.target.value as string));
        window.krlang = event.target.value;
      };

    return (
        <Box sx={{ paddingBottom: "40px", paddingLeft: "30px" }}>
            <Select  sx={{}} variant="standard"
                        value={lang}
                        disableUnderline
                        onChange={handleChange}>
                        <MenuItem value={"de"}>DE</MenuItem>
                        <MenuItem value={"en"}>EN</MenuItem>
                    </Select>

             <IconButton
                size="large"
                edge="start"
                onClick={handleClick}
            >
                <MenuIcon />
            </IconButton>

           <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MainNavigationItemMobile name={ui[lang].MainNavigationItem.about} path="about" callback={handleClose} />
                <MainNavigationItemMobile name={ui[lang].MainNavigationItem.artist} path="artist" callback={handleClose} />
                <MainNavigationItemMobile name={ui[lang].MainNavigationItem.galleryall} path="gallery/all" callback={handleClose} />
                <MainNavigationItemMobile name={ui[lang].MainNavigationItem.virtualtour}  path="virtualtour" callback={handleClose} />
                {false &&
                    <MainNavigationItemMobile name="Künstlerbücher" path="" callback={handleClose} />
                }
                <MainNavigationItemMobile name={ui[lang].MainNavigationItem.visit} path="visit" callback={handleClose} />


            </Menu>

        </Box>
    );
}