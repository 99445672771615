import { Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { change } from "../state/Nav";
import { TextBody, TextBox, TextHeader } from "../Styles";
import { hideKrpano } from "../Util";
import { ui } from "../i18n";
import { RootState } from "../store";

export default function Oeuvre() {
        const lang = useSelector((state: RootState) => state.language.value);
        const dispatch = useDispatch();
        dispatch(change("art"));

        hideKrpano();

        return (<Box sx={{ paddingBottom: "60px" }}>

                <TextBox>
                        <TextHeader>
                                {ui[lang].Art.p1}
                        </TextHeader>
                        <TextBody marginTop="2px">
                                {ui[lang].Art.b1}
                        </TextBody>
                        <TextHeader marginTop="20px">
                                {ui[lang].Art.p2}
                        </TextHeader>
                        <TextBody marginTop="2px">
                                {ui[lang].Art.b2}
                        </TextBody>
                        <TextHeader marginTop="20px">
                                {ui[lang].Art.p3}
                        </TextHeader>
                        <TextBody marginTop="2px">
                                {ui[lang].Art.b3}
                        </TextBody>
                        <TextHeader marginTop="20px">
                                {ui[lang].Art.p4}
                        </TextHeader>
                        <TextBody marginTop="2px">
                                {ui[lang].Art.b4}
                        </TextBody>
                        <TextHeader marginTop="20px">
                                {ui[lang].Art.p5}
                        </TextHeader>
                        <TextBody marginTop="2px">
                                {ui[lang].Art.b5}
                        </TextBody>
                        <TextHeader marginTop="20px">
                                {ui[lang].Art.p6}
                        </TextHeader>
                        <TextBody marginTop="2px">
                                {ui[lang].Art.b6}
                        </TextBody>
                </TextBox>
        </Box>
        );
}