import { Box, FormControl, MenuItem, Select, SelectChangeEvent, useMediaQuery } from "@mui/material";
import MainNavigationItem from "./MainNavigationItem";
import MainNavigationMobile from "./MainNavigationMobile";
import { MediaQueryMobile } from "../Styles";
import { ui } from "../i18n";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import React from "react";
import { changeLang } from "../state/Language";

export default function MainNavigation() {
    const isMobile = useMediaQuery(MediaQueryMobile);
    const dispatch = useDispatch();
    const lang = useSelector((state: RootState) => state.language.value);
   
    const handleChange = (event: SelectChangeEvent) => {
        dispatch(changeLang(event.target.value as string));
        window.krlang = event.target.value;
      };
    
    return (
        <div>
            {!isMobile && ui && ui[lang] &&
                <Box sx={{ paddingBottom: "3.75vH" }}>
                    <MainNavigationItem name={ui[lang].MainNavigationItem.about} path="about" />
                    <MainNavigationItem name={ui[lang].MainNavigationItem.artist} path="artist" />
                    <MainNavigationItem name={ui[lang].MainNavigationItem.art} path="art" />
                    <MainNavigationItem name={ui[lang].MainNavigationItem.galleryall} path="gallery/all" />
                    <MainNavigationItem name={ui[lang].MainNavigationItem.virtualtour} path="virtualtour" />
                    <MainNavigationItem name={ui[lang].MainNavigationItem.visit} path="visit" />
                    
                    <Select  variant="standard"
                        value={lang}
                        disableUnderline
                        onChange={handleChange}>
                        <MenuItem value={"de"}>DE</MenuItem>
                        <MenuItem value={"en"}>EN</MenuItem>
                    </Select>
                    
                    {false &&
                        <div>
                            <MainNavigationItem name="Künstlerbücher" path="" />
                            <MainNavigationItem name="Shop" path="shop" />
                        </div>
                    }
                </Box>
            }
            {isMobile &&
                <MainNavigationMobile />
            }
        </div>
    );
}