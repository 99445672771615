import { Close, Fullscreen, OndemandVideo, ZoomIn } from '@mui/icons-material';
import { Box, ButtonGroup, IconButton, MenuItem, Modal, Select, SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen';
import { useNavigate } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import i18n from '../i18n';
import { MediaQueryMobile } from '../Styles';
import PaintingTitle from './PaintingTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { changeLang } from "../state/Language";

declare global {
    interface Window { restoreFS: boolean; currentTime: number }
}
type Lang = "de" | "en";

export default function Painting(params: {
    name: string /* Key for painting */, fromTour?: boolean /* Used from virtual tour*/,
    fullscreenhandle?: FullScreenHandle /* */, hidden?: boolean /* Indicates if modal is visible or not*/
}) {
    const lang = useSelector((state: RootState) => state.language.value);
    let llang = lang;
    if (params.fromTour) {
        llang = "de";
    }
    const dispatch = useDispatch();

    const vidRef = useRef<HTMLVideoElement>(null);
    const isMobile = useMediaQuery(MediaQueryMobile);
    const handle = useFullScreenHandle();
    const theme = useTheme();
    const [open, setOpen] = useState(true); // Indicates if modal is open or not
    const [workdesc, setWorkdesc] = useState(i18n(params.name!)); // Metadata for the painting to be displayed
    const [mode, setMode] = useState((workdesc.hasVideo[llang as Lang] === "true") ? "video" : "image"); // Indicates if video or image should be shown
    const nav = useNavigate();

    //   const lang = useSelector((state: RootState) => state.language.value);

    // Calculate ids of elements referenced in plain javascript
    // Default is a unique id
    const idPostFix = Math.floor(Math.random() * 1000).toString();
    let modalId = "modal" + idPostFix;
    let imgId = "img" + idPostFix;
    let vidId = "vid" + idPostFix;
    let imgBoxId = "imgbox" + idPostFix;


    // For components used from virtual tour, the id depends on orientation and existence of a video
    if (params.fromTour) {
        const wd = i18n(params.name!);
        if (wd.format === "landscape") {
            // landscape
            modalId = "modal-l";
            imgId = "img-l";
            if (wd.hasVideo["de"] === "true") {
                // video exists
                imgId = "img-v-l";
                modalId = "modal-v-l";
                vidId = "vid-v-l";
            }
        }
        else {
            // portrait
            modalId = "modal-p";
            imgId = "img-p";
            if (wd.hasVideo["de"] === "true") {
                // video exists
                imgId = "img-v-p";
                modalId = "modal-v-p";
                vidId = "vid-v-p";
            }
        }
        console.debug(modalId);
    }

    // Calculate width, height, and padding depending on orientation, fullscreen mode, viewport size
    let w = theme.painting.landscape.width;
    let h = theme.painting.landscape.height;
    let p = theme.painting.p;
    let pb = p;

    if (workdesc.format === "portrait") {
        // portrait
        w = theme.painting.portrait.width;
        h = theme.painting.portrait.height;
    }
    const wvh = window.visualViewport!.height - 64 - 2 * 20;
    const wvw = window.visualViewport!.width - 20 - 2 * 20;

    const fullsize = handle.active;
    if (isMobile && (fullsize || h > window.visualViewport!.height || w > window.visualViewport!.width)) {
        p = "0px";
        // Correct if in fullscreen mode or viewport too small
        if (w * window.visualViewport!.height > h * window.visualViewport!.width) {
            // Image is more landscape than screen
            h = h * window.visualViewport!.width / w;
            w = window.visualViewport!.width;
        }
        else {
            // Image is more portrait than screen
            w = w * window.visualViewport!.height / h;
            h = window.visualViewport!.height;
        }
    }
    else if (!isMobile && (fullsize || h > wvh || w > wvw)) {
        pb = "0";
        if (fullsize) {
            p = "0";
            // Correct if in fullscreen mode or viewport too small
            if (w * (window.visualViewport!.height) > h * (window.visualViewport!.width)) {
                // Image is more landscape than screen
                h = h * (window.visualViewport!.width) / w;
                w = window.visualViewport!.width;
            }
            else {
                // Image is more portrait than screen
                w = w * (window.visualViewport!.height) / h;
                h = window.visualViewport!.height;
            }
        }
        else {
            // Correct if in fullscreen mode or viewport too small
            if (w * (window.visualViewport!.height - 64 - 20) > h * (window.visualViewport!.width - 20 - 2 * 20)) {
                // Image is more landscape than screen
                h = h * (window.visualViewport!.width - 20 - 2 * 20) / w;
                w = window.visualViewport!.width - 20 - 2 * 20;
            }
            else {
                // Image is more portrait than screen
                w = w * (window.visualViewport!.height - 64 - 20) / h;
                h = window.visualViewport!.height - 64 - 20;
            }
        }
    }

    const displayModal = (params.hidden) ? "none" : "flex"; // Show or hide modal 
    const displayVideo = (mode === "video") ? "block" : "none"; // Show or hide video element
    const displayImg = (mode === "image") ? "flex" : "none"; // Show or hide image element

    const autoplay = (params.hidden || mode !== "video") ? false : true; // Enable autoplay or not

    // Calculate paths of image and video
    const imagePath = "/img/large/" + workdesc.name + ".jpg";
    const videoPath = "/video/" + llang + "/" + workdesc.name + ".mp4";

    // Detect if image source has been altered by plain javascript (non-React) in virtual tour
    const imgEl: HTMLImageElement | null = document.getElementById(imgId) as HTMLImageElement | null;
    if (imgEl) {
        if (!imgEl.src.endsWith(imagePath)) {
            // Altered!
            let s = imgEl.src.substring(imgEl.src.lastIndexOf("/") + 1);
            s = s.substring(0, s.indexOf("."))
            // Reload with correct painting
            setWorkdesc(i18n(s));
        }
    }

    let isClosing = false; // Flag set when modal is closed using cancel button
    const handleChange = (event: SelectChangeEvent) => {
        dispatch(changeLang(event.target.value as string));
        window.krlang = event.target.value;
    };

    return (
        <div>
            {!isMobile &&
                // Desktop / tablet mode: fullscreen is not default
                // Modal
                <Modal id={modalId} style={{ display: displayModal }} open={open} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <FullScreen handle={handle}>
                        {!fullsize &&
                            // Normal, no fullscreen
                            < Box sx={{ bgcolor: 'kr.main', p: p, paddingBottom: pb }}>
                                <Box display={displayVideo} sx={{ width: w, height: h, boxShadow: "5" }}>
                                    {/* Video */}
                                    <video id={vidId} ref={vidRef} controls autoPlay={autoplay} style={{ width: "100%", height: "100%" }} src={videoPath}
                                        onPlay={() => {
                                            // Set time to where the video was paused previously as stored in window.currentTime
                                            if (window.currentTime) {
                                                const vid = document.getElementById(vidId) as HTMLVideoElement;
                                                if (vid) vid.currentTime = window.currentTime;
                                            }
                                        }}
                                        onPause={() => {
                                            // Store time when video was paused unless the pause was caused by activation of the cancel button
                                            if (!isClosing) {
                                                const vid = document.getElementById(vidId) as HTMLVideoElement;
                                                window.currentTime = vid.currentTime;
                                            }
                                        }}
                                    ></video>
                                </Box>

                                {/* Image */}
                                <Box id={imgBoxId} sx={{ margin: "auto", display: displayImg, alignItems: "center", justifyContent: "center", boxShadow: "5" }}>
                                    <TransformWrapper>
                                        <TransformComponent>
                                            <img id={imgId} src={imagePath} alt="test" style={{ margin: "auto", width: "auto", maxHeight: h }} />
                                        </TransformComponent>
                                    </TransformWrapper>
                                </Box>

                                {/* Title and buttons */}
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Box />
                                    {/* Title */}
                                    <PaintingTitle title={workdesc.title[llang as Lang]} idPrefix={modalId}></PaintingTitle>
                                    {/* Buttons */}
                                    <Box component="div" sx={{ paddingTop: theme.painting.title.p, display: "flex", justifyContent: "right" }}>
                                        <ButtonGroup variant="contained" aria-label="Basic button group">
                                            {/*
                                            <Select variant="standard"
                                                value={lang}
                                                disableUnderline
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"de"}>DE</MenuItem>
                                                <MenuItem value={"en"}>EN</MenuItem>
                                            </Select>
                                            */}
                                            {mode === "video" &&
                                                // Magnifier button for switching to image display
                                                <IconButton size="small" onClick={() => {
                                                    // Pause and hide video
                                                    const vid = document.getElementById(vidId) as HTMLVideoElement;
                                                    if (vid) {
                                                        vid.pause();
                                                        vid.autoplay = false;
                                                        vid.style.display = "none";
                                                    }
                                                    // Reload in image mode
                                                    setMode("image")
                                                }}>
                                                    <ZoomIn></ZoomIn>
                                                </IconButton>
                                            }
                                            {mode === "image" && workdesc.hasVideo[llang as Lang] === "true" &&
                                                // Video button for switching to video display
                                                <IconButton size="small" onClick={() => {
                                                    // Show and play video
                                                    const vid = document.getElementById(vidId) as HTMLVideoElement;
                                                    if (vid) {
                                                        vid.style.display = "block";
                                                    }
                                                    vidRef.current?.play();
                                                    // Reload in video mode
                                                    setMode("video")
                                                }}>
                                                    <OndemandVideo></OndemandVideo>
                                                </IconButton>
                                            }
                                            {mode === "image" &&
                                                // Fullscreen button for entering fullscreen mode
                                                <IconButton size="small" onClick={() => { handle.enter() }}>
                                                    <Fullscreen></Fullscreen>
                                                </IconButton>
                                            }

                                            {/* Cancel button for closing the modal */}
                                            <IconButton size="small" onClick={() => {
                                                // Flag closing
                                                isClosing = true;
                                                // Closing actions depend on whether the modal was called from virtual tour or not
                                                if (params.fromTour) {
                                                    // Virtual tour
                                                    // Hide modal
                                                    document.getElementById(modalId)!.style.display = "none";
                                                    // Pause video
                                                    const vid = document.getElementById(vidId) as HTMLVideoElement;
                                                    if (vid) vid.pause();

                                                    // Restore virtual tour in fullscreen mode
                                                    if (window.restoreFS) {
                                                        const krpano = document.getElementById("krpano-kr") as HTMLDivElement;
                                                        krpano.requestFullscreen();
                                                    }
                                                }
                                                else {
                                                    // Normal call: normally from gallery
                                                    // Close modal
                                                    setOpen(false);
                                                    // Navigate to gallery series
                                                    nav("/gallery/Serie_" + workdesc.serie)
                                                }
                                                // Reset video time
                                                window.currentTime = 0;
                                            }}>
                                                <Close></Close>
                                            </IconButton>
                                        </ButtonGroup>
                                    </Box>
                                </Box>
                            </Box>
                        }

                        {fullsize &&
                            // Fullscreen for desktop / tablet
                            < Box sx={{ bgcolor: 'kr.main', p: p }}>
                                {/* Video */}
                                <Box display={displayVideo} sx={{ width: "100vw", height: "100vh", bgcolor: 'kr.main' }}>
                                    <video id={vidId} ref={vidRef} controls autoPlay={autoplay} style={{ width: "100%", height: "100%" }} src={videoPath} ></video>
                                </Box>
                                {mode === "image" &&
                                    // Image
                                    < Box sx={{ margin: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <TransformWrapper initialPositionX={(window.visualViewport!.width - w) / 2} initialPositionY={(window.visualViewport!.height - h) / 2}>
                                            <TransformComponent wrapperStyle={{ width: "100vw", maxHeight: "100vh" }}>
                                                <img id={imgId} src={imagePath} alt="test" style={{ margin: "auto", width: "auto", maxHeight: h }} />
                                            </TransformComponent>
                                        </TransformWrapper>
                                    </Box>
                                }
                            </Box>
                        }
                    </FullScreen >
                </Modal >
            }

            {
                isMobile &&
                // Mobile mode: fullscreen is default
                // Box covering viewport
                <Box id={modalId} sx={{ bgcolor: 'kr.main', p: "0px", display: displayModal, justifyContent: "center", alignItems: "center", width: window.visualViewport!.width, height: window.visualViewport!.height }}>
                    {/* Video */}
                    <Box display={displayVideo} sx={{ width: w, height: h, boxShadow: "5" }}>
                        <video id={vidId} ref={vidRef} controls autoPlay={autoplay} style={{ width: "100%", height: "100%" }} src={videoPath} ></video>
                    </Box>

                    {/* Image */}
                    <Box sx={{ display: displayImg }}>
                        <TransformWrapper initialPositionX={(window.visualViewport!.width - w) / 2} initialPositionY={(window.visualViewport!.height - h) / 2}>
                            <TransformComponent wrapperStyle={{ width: "100vw", height: "100vh" }} >
                                <img id={imgId} src={imagePath} style={{ display: "block", width: "100vw", height: "auto" }} />
                            </TransformComponent>
                        </TransformWrapper>
                    </Box>
                    {/* Buttons */}
                    <ButtonGroup variant="contained" aria-label="Basic button group" sx={{ bgcolor: 'kr.main', position: "absolute", bottom: 20, right: 20 }}>
                        {mode === "video" &&
                            // Magnifier button for switching to image display
                            <IconButton size="small" onClick={() => {
                                // Pause video
                                vidRef.current?.pause();
                                vidRef.current!.autoplay = false;

                                // Reload in image mode
                                setMode("image")
                            }}>
                                <ZoomIn></ZoomIn>
                            </IconButton>
                        }
                        {mode === "image" && workdesc!.hasVideo[llang as Lang] === "true" &&
                            // Video button for switching to video display
                            <IconButton size="small" onClick={() => {
                                vidRef.current?.play();
                                setMode("video")
                            }}>
                                <OndemandVideo></OndemandVideo>
                            </IconButton>
                        }

                        {/* Cancel button for closing the modal */}
                        <IconButton size="small" onClick={() => {
                            // Flag closing
                            isClosing = true;
                            // Closing actions depend on whether the modal was called from virtual tour or not
                            if (params.fromTour) {
                                document.getElementById(modalId)!.style.display = "none";
                                if (mode === "video") {
                                    const vid = document.getElementById(vidId) as HTMLVideoElement;
                                    vid!.pause();
                                    vid!.autoplay = false;
                                }
                                if (workdesc.hasVideo[llang as Lang] === "true" && mode === "image") {
                                    setMode("video");
                                }
                                if (document.fullscreenElement) {
                                    document.exitFullscreen().then(() => {
                                        // Restore virtual tour in fullscreen mode
                                        if (window.restoreFS) {
                                            const krpano = document.getElementById("krpano-kr-mobile") as HTMLDivElement;
                                            krpano.requestFullscreen();
                                        }
                                    })
                                };
                            }
                            else {
                                params.fullscreenhandle?.exit();
                            }
                            // Reset video time
                            window.currentTime = 0;
                        }}>
                            <Close></Close>
                        </IconButton>
                    </ButtonGroup>
                </Box>
            }
        </div >
    );
}