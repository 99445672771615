import { Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { change } from "../state/Nav";
import { TextBody, TextBox, TextHeader } from "../Styles";
import { hideKrpano } from "../Util";
import { ui } from "../i18n";
import { RootState } from "../store";

export default function Artist() {
        const lang = useSelector((state: RootState) => state.language.value);
        const dispatch = useDispatch();
        dispatch(change("artist"));

        hideKrpano();

        return (
                <Box sx={{ paddingBottom: "60px" }}>
                        <TextBox>
                                <TextHeader>
                                        {ui[lang].Artist.vita}
                                </TextHeader>
                                <TextBody marginTop="2px">
                                        {ui[lang].Artist.vitabody}
                                        <img src={"/artist.png"} style={{ display: "block", maxWidth: "600px", height: "auto", margin: "auto", paddingTop: "20px" }} />
                                </TextBody>
                                <Box component="div" sx={{ paddingTop: "10px", display: "flex", alignItems: "bottom", justifyContent: "center" }}>
                                        <Typography gutterBottom variant="subtitle1" >
                                        {ui[lang].Artist.vitacaption}
                                        </Typography>
                                </Box>
                        </TextBox>
                </Box>
        );
}