import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton } from "@mui/material";
import { useState } from "react";
import { TextBody, TextHeader } from "../Styles";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ui } from "../i18n";

declare global {
   interface Window { virtualhelp: boolean; }
}

export default function VirtualHelp() {
   const lang = useSelector((state: RootState) => state.language.value);
   const [hidden, setHidden] = useState(false);

   const modalDisplay = (hidden || window.virtualhelp) ? "none" : "flex";

   const w = Math.min(500, window.innerWidth - 80);

   return (
      <Dialog id="virtual-help" open={true} sx={{ display: modalDisplay, alignItems: "center", justifyContent: "center" }}>
         <Box sx={{ width: w, borderRadius: 3, bgcolor: 'kr.main', padding: "20px", paddingBottom: "40px" }}>
            <TextHeader>
               {ui[lang].VirtualHelp.help}
            </TextHeader>
            <TextBody marginTop="10px">
               {ui[lang].VirtualHelp.helpbody1}
            </TextBody>
            <TextBody marginTop="10px">
               {ui[lang].VirtualHelp.helpbody2}
            </TextBody>
            <TextBody marginTop="10px">
               {ui[lang].VirtualHelp.helpbody3} <img src="/icon/help1.png" style={{ display: "inline", width: "20px", height: "20px" }}></img> {ui[lang].VirtualHelp.helpbody4}
            </TextBody>
            <TextBody marginTop="10px">
               {ui[lang].VirtualHelp.helpbody5}
            </TextBody>
            <IconButton sx={{ position: "absolute", bottom: "10px", right: "10px" }} size="small" onClick={() => {
               window.virtualhelp = true;
               setHidden(true)
            }}>
               <Close></Close>
            </IconButton>
         </Box>
      </Dialog >
   );
}