import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton } from "@mui/material";
import { useState } from "react";
import { TextBody, TextHeader } from "../Styles";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ui } from "../i18n";

export default function VirtualInfo() {
   const lang = useSelector((state: RootState) => state.language.value);
   const [hidden, setHidden] = useState(true);

   const modalDisplay = (hidden) ? "none" : "flex";
   const w = Math.min(500, window.innerWidth - 80);

   return (
      <Dialog id="virtual-info" open={true} sx={{ display: modalDisplay, alignItems: "center", justifyContent: "center" }}>
         <Box sx={{ width: w, borderRadius: 3, bgcolor: 'kr.main', padding: "20px" }}>
            <TextHeader>
               {ui[lang].VirtualInfo.info}
            </TextHeader>
            <TextBody marginTop="10px">
            {ui[lang].VirtualInfo.infobody}
               <table id="help-controlbar">
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/PrevScene.png" /></td>
                     <td style={{ width: "40%" }}>{ui[lang].VirtualInfo.PrevScene}</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/NextScene.png" /></td>
                     <td style={{ width: "40%" }}>{ui[lang].VirtualInfo.NextScene}</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/RotateLeft.png" /></td>
                     <td>{ui[lang].VirtualInfo.RotateLeft}</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/RotateRight.png" /></td>
                     <td>{ui[lang].VirtualInfo.RotateRight}</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/RotateUp.png" /></td>
                     <td>{ui[lang].VirtualInfo.RotateUp}</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/RotateDown.png" /></td>
                     <td>{ui[lang].VirtualInfo.RotateDown}</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/MoveForward.png" /></td>
                     <td>{ui[lang].VirtualInfo.MoveForward}</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/MoveBackward.png" /></td>
                     <td>{ui[lang].VirtualInfo.MoveBackward}</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/Fullscreen.png" /></td>
                     <td>{ui[lang].VirtualInfo.Fullscreen}</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/EndFullscreen.png" /></td>
                     <td>{ui[lang].VirtualInfo.EndFullscreen}</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/HideControlBar.png" /></td>
                     <td>{ui[lang].VirtualInfo.HideControlBar}</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/ShowControlBar.png" /></td>
                     <td>{ui[lang].VirtualInfo.ShowControlBar}</td>
                  </tr>
                  <tr>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/Thumbnails.png" /></td>
                     <td>{ui[lang].VirtualInfo.Thumbnails}</td>
                     <td style={{ padding: "5px" }}><img style={{ display: "inline", width: "20px", height: "20px" }} src="icon/help1.png" /></td>
                     <td>{ui[lang].VirtualInfo.help}</td>
                  </tr>
               </table>
               <p></p>
               {ui[lang].VirtualInfo.body1}
               <p></p>
               {ui[lang].VirtualInfo.body2}
            </TextBody>
            <IconButton sx={{ position: "absolute", bottom: "10px", right: "10px" }} size="small" onClick={() => {
               if (hidden) {
                  const s = document.getElementById("virtual-info") as HTMLDivElement;
                  s.style.display = "none";
               }
               else {
                  setHidden(true)
               }
            }}>
               <Close></Close>
            </IconButton>
         </Box>
      </Dialog >
   );
}