/**
 * Contains all common and language-dependent text elements of work details and language-dependent UI text elements 
 * Common elements are stored as top-level properties, language-dependent elements are stored below the respective
 * language property.
 */
export let i18n_messages: { "name": string, "title": string }[];
export let workdetails: { "name": string, "serie": string, "format": string, "versionNr": string, "hasVideo": { "de": string; "en": string }, "title": { "de": string; "en": string }}[];
export let ui: any = {};

/** 
 * Loads common and language-dependent elements from files whose names are obtained by adding postfixes to filename.
 * The languages available are given in locals.
*/
export function load_messages(locals: string[]) {
    return new Promise((resolve, reject) => {
        let filename = "/data/workdetails";
        // Load common elements
        fetch(filename + '_common.json')
            .then(response => response.json())
            .then(data => {
                workdetails = data;
            });

        // Load language-dependent elements 
        locals.forEach((item, idx) => {
            fetch("/data/ui_" + item + '.json')
                .then(response => response.json())
                .then(data => {
                    ui[item] = data;
                })
        });
        locals.forEach((item, idx) => {
            fetch(filename + '_' + item + '.json')
                .then(response => response.json())
                .then(data => {
                    i18n_messages = data;
                    if (idx == locals.length - 1) resolve("");
                });
        }
        );
    });

}

export interface Workdesc { "name": string, "serie": string, "format": string, "versionNr": string, "hasVideo": { "de": string; "en": string }, "title": { "de": string; "en": string } };

/**
 * Returns common and/or language-dependent text elements
 */
export default function i18n(key: string): Workdesc {
    let y: Workdesc;
    for (let x of workdetails) {
        if (x.name === key) {
            y = { "name": x.name, "serie": x.serie, "format": x.format, "versionNr": x.versionNr, "hasVideo": x.hasVideo, "title": x.title };
        }
    };
 
    return y!;
}

export function getSeries(serie: string): { name: string, title: { "de": string, "en": string }, image: string, format: string, versionNr: string }[] {
    let itemData: { name: string, title: { "de": string, "en": string }, image: string, format: string, versionNr: string }[] = [];
    for (let x of workdetails) {
        if (("Serie_" + x.serie) === serie) {
            const y = i18n(x.name);
            itemData.push({ ...y, image: "small/" + x.name });
        }
    };

    return itemData;
}

